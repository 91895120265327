<template>
    <Widgets type="home" ref="Widgets" />
</template>

<script>
import Widgets from "@/components/Widgets"

export default {
    name: 'home',
    components: { Widgets },

    methods: {
        rerender_tab(){
            if (this.$refs.Widgets) this.$refs.Widgets.rerender();
        }
    }
}
</script>
